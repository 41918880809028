import { axiosPost, axiosPostDownload } from '@/utils/request'
import axios from "axios";
import { getNodeTypeIdStr, joinComma } from "@/utils/stringutil";
import store from "@/store/index"
import { getUserCompanyStr } from "@/utils/cache";
import { reject } from 'underscore';
/**
 * 数据相关操作
 */

/**
 * 获取组织树
 */
export const getSimpleTreeNode = data => {
  return axiosPost({
    url: '/nodeInfo/getSimpleTreeNode',
    data: data,
    loading: false
  })
}
/**
 * 获取用户的数据树
 */
export const getSimpleTreeNodeByPermission = nodeIds => {
  return axiosPost({
    url: '/nodeInfo/getSimpleTreeNodeByPermission',
    data: {
      nodeIds: nodeIds
    },
    loading: false
  })
}
/**
 * 通过id 获取子节点
 */
export const getChild = data => {
  return axiosPost({
    url: `/nodeInfo/getChildNode`,
    data: data,
    loading: false
  })
}

// 设置搜索的用户节点
const setSelectId = data => {
  // 无监听数据直接返回
  if (data.watchData === null || data.watchData === undefined || data.watchData.nodeId === undefined) {
    data.companyUnitId = '123456789qwerasdzxc' // 设置为无法查询出数据的条件
  } else {
    data[getNodeTypeIdStr(data.watchData.nodeType)] = data.watchData.nodeId
  }
  delete data.watchData
}
// 设置自定义搜索条件
const setSearchType = data => {
  for (const key in data.searchParams) {
    if (data.searchParams[key] instanceof Array) {
      key.split(',').forEach((keyItem, keyIndex) => {
        data[keyItem] = data.searchParams[key][keyIndex]
      })
    } else {
      data[key] = data.searchParams[key]
    }

  }
  delete data.searchParams
}

// 设置数据
export const setData = data => {
  setSelectId(data)
  setSearchType(data)
  delete data.parentRequestParams
  delete data.watchData
  // const permissionList = []
  // store.getters.loginInfo.userDataPermissions.forEach(item => {
  //   if (item.permissionType === 'node') {
  //     permissionList.push(item.relId)
  //   }
  // })
  // data.nodeIds = joinComma('',permissionList)

}

/**
 * 查询表最新数据
 */
export const getMeterReadingLastListByPage = data => {
  return axiosPost({
    url: '/meterReadingLast/getMeterReadingLastListByPage',
    data: data,
    uniqueRequest: true,
    loading: false
  })
}
/**
 * 获取历史数据
 * @param data
 * @returns {Promise<unknown>}
 */
export const getMeterReadingListByPage = data => {
  return axiosPost({
    url: '/meterReading/getMeterReadingListByPage',
    data: data,
    uniqueRequest: true,
    loading: false
  })
}

/**
 *  获取月用水数据
 * @param {*} data 
 * @returns 
 */
export const getMonthlyMeterReadingListByPage = data => {
  return axiosPost({
    url: '/meterReadingMonth/getMonthlyMeterReadingListByPage',
    data: data,
    uniqueRequest: true,
    loading: false
  })
}

/**
 *  获取日用水数据
 * @param {*} data 
 * @returns 
 */
export const getDayMeterReadingListByPage = data => {
  return axiosPost({
    url: '/meterReadingDay/getDailyMeterReadingListByPage',
    data: data,
    uniqueRequest: true,
    loading: false
  })
}


/**
 * 获取历史数据（新接口，灵动数据库）
 * @param {*} data 
 * @returns 
 */
export const getWaterDataListByPermission = data => {
  return axiosPost({
    url: '/meterReading/getWaterDataListByPermission',
    data: data,
    uniqueRequest: true,
    loading: false
  })
}
export const getWaterLastDataListByPermission = data => {
  return axiosPost({
    url: '/meterReadingLast/getWaterLastDataListByPermission',
    data: data,
    loading: false
  })
}
// 处理时间异常数据
export const handleTimeExceptionData = eqptIds => {
  return axiosPost({
    url: '/meterReadingLast/handleTimeExceptionData',
    data: {
      readingIds: eqptIds
    },
    loading: false,
    tips: false,
  })
}
/**
 * 日用水lindorm
 * @param {*} data 
 * @returns 
 */
export const getWaterDayDataListByPermission = data => {
  return axiosPost({
    url: '/meterReadingDay/getWaterDayDataListByPermission',
    data: data,
    uniqueRequest: true,
    loading: false
  })
}
/**
 * 月用水lindorm
 * @param {*} data 
 * @returns 
 */
export const getWaterMonthDataListByPermission = data => {
  return axiosPost({
    url: '/meterReadingMonth/getWaterMonthDataListByPermission',
    data: data,
    uniqueRequest: true,
    loading: false
  })
}
/**
 * 控制履历lindorm
 * @param {*} data 
 * @returns 
 */
export const valveResumeLindomByPermission = data => {
  return axiosPost({
    url: '/equipmentCmdInfo/getCmdInfoListByPermission',
    data: data,
    uniqueRequest: true,
    loading: false
  })
}
export const getCmdInfoListByNodeId = data => {
  return new Promise(async (resolve, reject) => {
    if (!data.sort) {
      delete data.sort
    } 
    axiosPost({
      url: '/equipmentCmdInfo/getCmdInfoListByNodeId',
      data: data,
      uniqueRequest: true,
      loading: false
    }).then(data => {
      resolve(data)
    }).catch(err => {
      reject(err)
    })
  })
}
export const getCollectorCmdInfoList = data => {
  return new Promise(async (resolve, reject) => {
    if (!data.sort) {
      delete data.sort
    } 
    axiosPost({
      url: '/collectorInfo/getCollectorCmdInfoList',
      data: data,
      uniqueRequest: true,
      loading: false
    }).then(data => {
      resolve(data)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 获取异常信息,通过节点id
 * @param data
 * @returns {Promise<unknown>}
 */
export const selectExceptionInforListByNodeId = data => {
  return axiosPost({
    url: '/eqptExceptionInfo/selectExceptionInfoListByNodeId',
    data: data,
    loading: false
  })
}

/**
 * 获取异常信息列表，通过设备id
 * @param data
 * @returns {Promise<unknown>}
 */
export const selectExceptionInforListByEqptId = data => {
  return axiosPost({
    url: '/eqptExceptionInfo/selectExceptionInfoListByEqptId',
    data: data,
    loading: false
  })
}



/** 获取一个水表的历史数据
 */
export const getMeterReadingListByEqptId = async data => {
  if (!data.companyId) {
    const companyId = await getUserCompanyStr()
    data.companyId = companyId
  }
  return axiosPost({
    url: '/meterReading/getMeterReadingListByPage',
    data: data,
    loading: false
  })
}


/**
 * 通过设备id 获取 echarts 数据 运行数据 
 * @param data
 * @returns {Promise<unknown>}
 */
export const getEchartsDataByEqptId = data => {
  return axiosPost({
    url: '/meterReading/countDailyWaterConsumption',
    data: data,
    loading: false
  })
}

/**
 * 通过设备id 获取 echarts 数据日用水量数据
 * @param data
 * @returns {Promise<unknown>}
 */
export const getDayEchartsDataByEqptId = data => {
  return axiosPost({
    url: '/getMeterDataCount/getDailyWaterConsumptionCount',
    data: data,
    loading: false
  })
}

/**
 * 导出数据
 * @param data
 */
export const exportData = data => {
  axiosPostDownload({
    url: '/commonExportExcel/exportExcelByRecord',
    data: data,
  })
}

// 导出所有数据
export const exportAllData = data => {
  axiosPostDownload({
    url: '/commonExportExcel/exportExcelByUrl',
    data: data,
  })
}

// 获取批次号
export const getEqptProductionBatch = async data => {
  const companyId = await getUserCompanyStr()
  return axiosPost({
    url: '/nodeEqptRel/gourpByProductionBatch',
    data: {
      companyId: companyId,
      ...data
    },
    loading: false,
  })
}

/**
 * 数据详情，统计月用水数据
 * @param {*} data 
 * @returns 
 */
export const getMonthlyWaterConsumptionCount = data => {
  return axiosPost({
    url: "/getMeterDataCount/getMonthlyWaterConsumptionCount",
    data: data,
    loading: false
  })
}


/**
 * 查询售水记录
 * @param {*} data 
 * @returns 
 */
export const selectWaterSalesRecords = data => {
  return axiosPost({
    url: "/waterSales/selectWaterSalesByNode",
    data: data,
    loading: false,
    uniqueRequest: true,
  })
}



/**
 * 查询周期用水数据
 * @param {*} data 
 * @returns 
 */
export const selectMeterReadingCycleData = data => {
  return axiosPost({
    url: "/meterReadingCycle/getWaterCycleDataListByPermission",
    data: data,
    loading: false
  })
}


/**
 * 查询日冻结数据
 * @param {Object} data 
 * @returns 
 */
export const selectEqptFreezeDayData = data => {
  return axiosPost({
    url: "/eqptFreezeDay/selectEqptFreezeDayData",
    data: data,
    loading: false
  })
}

/**
 * 查询设备最新参数接口
 * @param {Object} data 
 * @returns 
 */
export const getEquipmentCmdParamsInfo = data => {
  return axiosPost({
    url: "/equipmentCmdParams/getEquipmentCmdParamsInfo",
    data: data,
    loading: false
  })
}

export const deleteWaterSaleRecords= data =>{
  return axiosPost({
    url: "/waterSales/updateWaterSales",
    data: data,
    loading: false
  })
}

/**
 * 查询井盖报警器最新数据
 * @param {Object} data 
 * @returns 
 */
export const selectManholeCoverMonitorLastData = data => {
  return axiosPost({
    url: "/manholeCoverMonitorLast/selectManholeCoverMonitorLastData",
    data: data,
    loading: false
  })
}
/**
 * 查询井盖报警器最新数据lindom
 * @param {Object} data 
 * @returns 
 */
export const getManholeCoverMonitorLastListByPermission = data => {
  return axiosPost({
    url: "/manholeCoverMonitorLast/getManholeCoverMonitorLastListByPermission",
    data: data,
    loading: false
  })
}


/**
 * 查询井盖报警器最新数据lindom,此接口触发新的求情会停止前一个请求，报警器地图使用
 * @param {Object} data 
 * @returns 
 */
export const getManholeCoverMonitorLastlist4MapUniqueRequest = data => {
  return axiosPost({
    url: "/manholeCoverMonitorLast/getManholeCoverMonitorLastlist4Map",
    data: data,
    loading: false,
    uniqueRequest: true,
  })
}
/**
 * 查询井盖报警器历史数据
 * @param {Object} data 
 * @returns 
 */
export const selectManholeCoverMonitorData = data => {
  return axiosPost({
    url: "/manholeCoverMonitor/selectManholeCoverMonitorData",
    data: data,
    loading: false
  })
}
/**
 * 查询井盖报警器历史数据lindom
 * @param {Object} data 
 * @returns 
 */
export const getManholeCoverMonitorListByPermission = data => {
  return axiosPost({
    url: "/manholeCoverMonitor/getManholeCoverMonitorListByPermission",
    data: data,
    loading: false
  })
}
/**
 * 统计日告警值 echarts
 * @param {Object} data 
 * @returns 
 */
export const countDayWarningConsumption = data => {
  return axiosPost({
    url: "/manholeCoverMonitor/countDayWarningConsumption",
    data: data,
    loading: false
  })
}
/**
 * 报警高峰期
 * @param {Object} data 
 * @returns 
 */
export const countExceptionFastigium = data => {
  return axiosPost({
    url: "/manholeCoverMonitor/countExceptionFastigium",
    data: data,
    loading: false
  })
}

/**
 * 查询井盖报警器状态统计
 * @param {Object} data 
 * @returns 
 */
export const getEquipmentSignalQualityProportion = data => {
  return axiosPost({
    url: "/getManholeCoverMonitorCount/getEquipmentSignalQualityProportion",
    data: data,
    loading: false
  })
}
/**
 * 查询报警器水压统计
 * @param {Object} data 
 * @returns 
 */
export const getHydraulicPressureCount = data => {
  return axiosPost({
    url: "/getManholeCoverMonitorCount/getHydraulicPressureCount",
    data: data,
    loading: false
  })
}
/**
 * 查询井盖报警器温度统计
 * @param {Object} data 
 * @returns 
 */
export const getTemperatureCount = data => {
  return axiosPost({
    url: "/getManholeCoverMonitorCount/getTemperatureCount",
    data: data,
    loading: false
  })
}
/**
 * 查询井盖报警器电压统计
 * @param {Object} data 
 * @returns 
 */
export const getVoltageCount = data => {
  return axiosPost({
    url: "/getManholeCoverMonitorCount/getVoltageCount",
    data: data,
    loading: false
  })
}
/**
 * 查询井盖报警器异常高峰期
 * @param {Object} data 
 * @returns 
 */
export const countExceptionFastigiumLindorm = data => {
  return axiosPost({
    url: "/manholeCoverMonitor/countExceptionFastigiumLindorm",
    data: data,
    loading: false
  })
}
/**
 * 查询井盖报警器报警次数排行
 * @param {Object} data 
 * @returns 
 */
export const countExceptionPushTop = data => {
  return axiosPost({
    url: "/eqptExceptionInfo/countExceptionPushTop",
    data: data,
    loading: false
  })
}
/**
 * 重新计算日用水量
 * @param {Object} data 
 * @returns 
 */
export const reCalculateDayWaterConsumptionByDate = data => {
  return axiosPost({
    url: "/meterReadingDay/reCalculateDayWaterConsumptionByDate",
    data: data,
    loading: false,
    tips:true
  })
}
/**
 * 重新计算月用水量
 * @param {Object} data 
 * @returns 
 */
export const reCalculateMonthWaterConsumptionByDate = data => {
  return axiosPost({
    url: "/meterReadingMonth/reCalculateMonthWaterConsumptionByDate",
    data: data,
    loading: false,
    tips:true
  })
}