import eqptRouter from "@/router/modules/eqpt";

export default {
  login: {
    title: "用户登录",
    loginBtn: "登录",
    usernameRule: "用户名为必填项",
    passwordRule: "密码不能少于6位",
  },
  tagsView: {
    refresh: "刷新",
    closeRight: "关闭右侧",
    closeOther: "关闭其他",
  },
  universal: {
    confirm: "确定",
    cancel: "取消",
  },
  route: {
    home: "首页",
    dataHome: "数据管理",
    dataManage: "数据管理",
    waterData: "用水数据",
    waterLatest: "最新数据",
    waterHistory: "历史数据",
    waterHistoryLindom: "历史数据",
    waterDayLindom: "日用水",
    waterMonthLindom: "月用水",
    waterNewLindorm: "最新数据",
    valveResumeLindom: "控制履历lindorm",
    waterMonth: "月用水数据",
    waterDay: "日用水数据",
    dataStatistics: "数据统计",
    errorData: "异常告警",
    connectError: "通讯异常",
    dataError: "设备异常",
    userHome: "",
    sysMain: "系统管理",
    organizationManage: "组织机构管理",
    profile: "个人中心",
    dictManage: "字典管理",
    tenantManage: "租户管理",
    merchantManage: "商户管理",
    warnSetting: "警告设置",
    apsAuthorization: "软件授权",
    waterSalesRecords: "售水记录",
    exceptionManage: "异常管理",
    protocolManage: "通讯协议管理",
    messageManage: "短信管理",
    softWareVersion: "版本发布",
    user: "用户",
    permissionManage: "许可管理",
    basicsManage: "基础信息",
    userManage: "用户管理",
    eqptManage: "设备管理",
    eqptWarehouse: "设备仓库",
    eqptRecycleBin: "设备回收站",
    excelImport: "Excel导入",
    accountManage: "账号管理",
    userInfo: "员工信息",
    roleManage: "角色管理",
    operationManage: "操作管理",
    eqptControl: "设备控制",
    eqptDisguiser: "设备伪装管理",
    eqptDockRecords: "设备对接信息记录",
    eqptInfo: "数据详情",

    collectorManage: "采集器管理",
    collectConf: "采集策略",
    textMessage: "短信策略",
    sendTextMessage: "发送短信",
    messageLog: "短信日志",
    // 设备运维
    eqptOpManage: "设备运维",
    valveControl: "设备控制",
    valveResume: "控制履历",
    // 结算管理
    settlementMgmt: "费用中心",
    autoSettlement: "自动结算",
    manualSettlement: "手动结算",
    settleTaskList: "结算管理",
    settlementLatest: "最新数据",
    settlementHistory: "历史数据",
    settlementDate: "数据管理",
    settlement: "基础信息",
    receiptTem: "收据模板",
    payCharge: "缴费",
    payChargeDel: "缴费",
    manholeCoverMonitor: "报警器",
    manholeCoverHistoryDate: "报警器历史数据",
    manholeCoverLastDate: "报警器最新数据",
    manholeCoverAlarmData: "报警器报警数据",
    nbEqptManage: "nb设备管理",
    mapDisplay: "运行总览",
    workOrderData: "工单中心",
    workOrderDisposition: "处置",
    workOrderVerify: "审核",
    prePayFee:'预付费',
    postpaid:'后付费',
    waterPriceMode:'水价管理',
    chargingMode:'收费模式',
    recharge:'余额管理',
    costDetail:'费用明细',
    preOverdue:'预欠费管理',
    overdue:'欠费管理',
  },
  role: {
    buttonTxt: "新增角色",
    index: "序号",
    name: "名称",
    desc: "描述",
    action: "操作",
    assignPermissions: "分配权限",
    removeRole: "删除角色",
    dialogTitle: "新增角色",
    dialogRole: "角色名称",
    dialogDesc: "角色描述",
    updateRoleSuccess: "用户角色更新成功",
  },
  permission: {
    name: "权限名称",
    mark: "权限标识",
    desc: "权限描述",
  },
  dict: {
    addDictType: "添加字典类型",
    dictName: "字典名称",
    libraryCodePlaceholder: "请输入字典编码",
    libraryCodeRulesMessage: "字典编码为必填项",
    libraryNamePlaceholder: "请输入字典名称",
    libraryNameRulesMessage: "字典名称为必填项",
    libraryDescPlaceholder: "请输入字典描述",
  },
  excel: {
    importExcel: "excel 导入",
    exportExcel: "excel 导出",
    exportZip: "zip 导出",
    name: "姓名",
    mobile: "联系方式",
    avatar: "头像",
    role: "角色",
    openTime: "开通时间",
    action: "操作",
    show: "查看",
    showRole: "角色",
    defaultRole: "员工",
    addSuccess: "添加成功",
    modifySuccess: "修改成功",
    remove: "删除",
    removeSuccess: "删除成功",
    title: "导出为 excel",
    placeholder: "excel 文件名称",
    defaultName: "员工管理表",
    close: "取 消",
    confirm: "导 出",
    importSuccess: " 条员工数据导入成功",
    dialogTitle1: "确定要删除用户 ",
    dialogTitle2: " 吗？",
    roleDialogTitle: "配置角色",
    refresh: "刷新",
  },
  axios: {
    Requestfailedwithstatuscode404: "请求失败，状态代码为404",
    Requestfailedwithstatuscode500: "请求失败，状态代码为500",
    timeoutof5000msexceeded: "请求超时",
    Requestfailedwithstatuscode504: "请求失败，状态代码504",
    Requestfailedwithstatuscode403: "请求失败，状态代码503",
  },
  request: {},
  showData: {
    simpleAddress: "地址",
    operateTime: "操作时间",
    operatorName: "操作人",
    readingTime: "读取时间",
    readingFlow: "正向累计流量",
    instantaneousFlow: "瞬时流量",
    workingHours: "工作时长",
    waterTemperature: "水温",
    waterPressure: "水压",
    purchaseTime: "购水次数",
    balance: "剩余金额",
    usageAmount: "表计数",
    usageAmountUnit: "表计数单位",
    zeroPaasSum: "过零金额",
    todayUnitPrice: "当日单价",
    todayUsageAmount: "当日使用量",
    workStatus: "设备状态",
    voltage: "电压",
    accruingAmount: "累计使用金额",
    userNo: "户号",
    signalQuality: "信号质量",
    reportCause: "上报原因",
    eqptId: "设备ID",
    eqptCode: "表号",
    eqptDn: "设备口径",
    concentratorId: "集中器ID",
    concentratorCode: "集中器编码",
    eqptType: "设备类型",
    meterType: "抄表类型",
    eqptComProtocol: "设备通讯协议",
    eqptCardNo: "设备物联网卡号",
    eqptComMode: "设备通讯方式",
    reverseFlow: "反向累计流量",
    readingId: "主键",
    libraryDesc: "描述",
    libraryCode: "编码",
    libraryName: "名称",
    libraryCreatetime: "创建时间",
    buyAmount: "累计购水金额",
    todayUsageAmountUnit: "当日使用量单位",
    dataHash: "数据hash值",
    valveStatus: "阀门状态",
    voltageStatus: "电压状态",
    imei: "imei",
    exceptionDescription: "异常描述",
    exceptionContent: "异常内容",
    exceptionTime: "异常时间",
    exceptionDisposeStatus: "异常处置状态",
    exceptionInformStatus: "异常通知状态",
    exceptionInformTime: "异常通知次数",
    dailyWaterConsumption: "日用水量",
    productionBatch: "生产批次",
    eqptModel: "设备型号",
    unreportDays: "未报天数",
    nodeUserName: "户主姓名",
    nodeUserPhone: "户主电话",
    cmdId: "指令ID",
    sendTime: "发送时间",
    cmdApi: "API",
    apiParams: "参数",
    cmdContent: "指令内容",
    sendStatus: "发送状态",
    responseContent: "响应内容",
    uuid: "命令唯一识别码",
    callbackContent: "回调内容",
    confirmTime: "确认时间",
    confirmStatus: "确认状态",
    callback: "回调",
    ser: "序列号",
    cmdType: "指令类型",
    cmdDescription: "指令描述",
    powerCapacitanceVoltage: "电容电压",
    valveStatusDetail: "阀门详情",
    eqptTypeChild: "设备子类型",
    controllerCode: "控制器编号",
    installationStatus: "安装状态",
    installationTime: "安装时间",
    eqptOriginalCode: "表底码",
    warehouseTime: "入库时间",
    nextVerifyTime: "下次校表时间",
    startUseTime: "启用日期",
    eqptSteelSeal: "设备钢印号",
    eqptRange: "设备量程",
    locationType: "位置类型",
    runningStatus: "运行状态",
    eqptFirm: "设备厂商",
    installationAddress: "安装地址",
    collectorCode: "采集器编号",
    oneLevelAddress: "一级地址",
    chargeId: "收费ID",
    remark: "备注",
    eqptComFeeValidity: "通讯费用有效期",
    address: "地址",
    signalIndex: "信号指数",
    monthTime: "日期",
    dayTime: "日期",
    meterReadingMonthlyCount: "月用水量总量",
    monthlyWaterConsumption: "月用水量",
    recentLoginTime: "采集器最近登录时间",
    recentActiveTime: "采集器最近活跃时间",
    recentOfflineTime: "采集器最近离线时间",
    onlineStatus: "采集器在线状态",
    collectorName: "采集器名称",
    collectorModel: "型号",
    networkMode: "联网方式",
    dataTransMode: "数据传输方式",
    heartCycle: "心跳周期",
    cmdStatus: "指令状态",
    surplusFlow: "剩余流量",
    maxRechargeOnce: "囤积量",
    alarmAmount: "报警量",
    loginOfflineTimeType: "时间类型",
    loginOfflineTime: "时间",
    cardNo: "卡号",
    salesTime: "售水时间",
    salesStatus: "状态",
    refundDescription: "退费说明",
    salesPrice: "单价",
    salesQuantity: "数量",
    salesAmount: "售水金额",
    salesCount: "售水次数",
    nodeName: "名称",
    nodeAddress: "地址",
    nodeType: "类型",
    signName: "短信签名",
    signType: "类型",
    templateCode: "短信模板",
    templateName: "模板名称",
    templateContent: "模板内容",
    longitude: "经度",
    latitude: "纬度",
    lockingStatus: "锁定状态",
    phoneNumber: "手机号",
    totalCount: "发送总数",
    successCount: "发送成功",
    failCount: "发送失败",
    otherCount: "未响应",
    sendContent: "发送内容",
    reportTime: "状态报告时间",
    errorCode: "错误码",
    errorMessage: "错误信息",
    templateType: "类型",
    applyStatus: "申请状态",
    auditStatus: "审核状态",
    applyTime: "申请时间",
    templateRemark: "说明",
    reason: "备注",
    protocolName: "协议名称",
    protocolCode: "协议编码",
    protocolNetworkingMode: "联网方式",
    protocolCommunicateServe: "通讯服务器",
    protocolFacturer: "协议厂家",
    protocolAdaptationDevice: "适配设备",
    protocolSupportOperation: "支持列操作",
    protocolMultipleOperation: "支持多选操作",
    protocolDocument: "文件",
    enabledTime: "启用时间",
    exceptionName: "异常名称",
    exceptionDesc: "异常描述",
    exceptionCode: "异常编码",
    exceptionCreatetime: "创建时间",
    authorizeStatus: "授权状态",
    auditTime: "授权时间",
    macAddress: "MAC地址",
    expireTime: "到期时间",
    warningName: "警告名称",
    warningType: "警告类型",
    warningValue: "警告阀值",
    warningCode: "告警编码",
    warningDesc: "告警内容",
    warningValueUnit: "单位",
    warningConfRemark: "警告配置说明",
    productModel: "产品型号",
    productProtpcol: "产品协议",
    productBatch: "产品批次号",
    subsequentOperation: "后续操作",
    userAccount: "账号",
    userTelnumber: "电话",
    userName: "名称",
    userGender: "性别",
    warningQuantity: "预警量",
    waterLadder: "当前阶梯",
    waterPrice: "当前水价",
    truthEqptCode: "设备号",
    targetEqptCode: "伪装设备号",
    targetEqptProtocol: "伪装设备协议",
    targetCollectorId: "采集器号",
    targetCollectorProtocol: "采集器协议",
    collectorProtocol: "采集器协议",
    targetIp: "服务器ip",
    port: "端口号",
    enable: "启用",
    meterReadingDay: "抄表日",
    settlementDay: "结算日",
    surplusAmountWanringValue: "报警金额",
    exceptionDisposeTime: "处置时间",
    exceptionDisposePerson: "责任人",
    exceptionType: "类型",
    timeStart: "开始时间",
    timeEnd: "结束时间",
    count: "数量",
    statisticstimeStart: "统计开始时间",
    statisticstimeEnd: "统计结束时间",
    waterInTemperature: "进水温度",
    waterUsageStartTime: "用水开始时间",
    waterUsageDuration:"用水时长",
    waterConsumption:"用水量",
    pressure:"压力",
    uploadServer:'服务器地址',
    uploadCycle:'上传周期',
    settlementDate:'结算日',
    synchronizationData:'机电同步数据',
    surplusAmountWarningValue:'剩余金额告警值',
    waterPrice1:'一阶梯水价',
    usage1:'一阶梯用量',
    waterPrice2:'二阶梯水价',
    usage2:'二阶梯用量',
    waterPrice3:'三阶梯水价',
    chargeMode:'计费方式',
    retryTimes:'重试次数',
    cmdReply:'指令回复',
    failReason:'失败原因',
    confName: '采集策略名称',
    version:'软件版本号',
    softwareAddress:'软件oss地址',
    forceUpdate:'是否强制更新',
    publishTime:'发布时间',
    softwareType:'软件类型',
    settlementStartTime:'结算开始时间',
    settlementEndTime:'结算结束时间',
    settleStartTime:'结算开始时间',
    settleEndTime:'结算结束时间',
    settleExecuteTime:'结算执行时间',
    settleTotalCount:'结算设备总数',
    settleFailCount:'结算失败设备数',
    settlementPrice:'结算单价',
    settlementMode:'结算方式',
    taskId:'任务id',
    taskName:'任务名称',
    startUsageAmount:'起始底数',
    startUsage:'起码',
    endUsageAmount:'终止底数',
    endUsage:'止码',
    totalUsage:'总用水',
    usage1Price:'阶段一单价',
    usage1Amount:'阶段一金额',
    usage2Price:'阶段二单价',
    usage2Amount:'阶段二金额',
    usage2Amount:'阶段二金额',
    usage3:'阶段三用水',
    usage3Price:'阶段三单价',
    usage3Amount:'阶段三金额',
    totalAmount:'总金额',
    totalUsageAmount:'总用水量',
    settlementExecuteTime:'结算执行时间',
    settlementAmount:'结算金额',
    settlementStatus:'结算状态',
    settlementFailReason:'结算失败原因',
    receiptStatus:'收据状态',
    chargeStatus:'收据状态',
    payee:'收款人',
    userAddress:'用户住址',
    collectionUnit:'收款单位',
    defaultTemplate:'默认模板',
    receiptUser:'收据所属用户',
    templateDesc:'模板描述',
    settlementTotalCount:'结算设备总数',
    settlementFailCount:'结算失败数据条数',
    taskStatus:'任务状态',
    tenantName:'租户名称',
    tenantDescription:'租户描述',
    contactName:'联系人名称',
    contactPhone:'联系人手机号',
    tenantCreatetime:'租户创建时间',
    temperature:'温度',
    liquidLevelOver:'浮球状态',
    leanAngle:'倾斜角度',
    getLocationFlag:'定位结果',
    armingState:'布防状态',
    waterImmersionState:'水浸状态',
    vibrationCount:'颠簸次数',
    liquidLevel:'水深',
    channelNumber:'通道号',
    alarmAngleValue:'报警角度',
    angleAlarmInterval:'角度报警间隔',
    alarmTemperatureValue:'报警温度',
    temperatureAlarmInterval:'温度报警间隔',
    reportInterval:'固定上报间隔',
    turbulenceAlarmTimes:'颠簸报警次数',
    deviceStatus:'注册状态',
    netStatus:'在线状态',
    registerTime:'注册时间',
    activateTime:'激活时间',
    recentOnlineTime:'最近在线时间',
    recentOfflineTimeNew: "最近离线时间",
    createTime: "创建时间",
    creatorName: "创建人",
    nbEqptBatch: "批次号",
    powerOntime: "加电时间",
    registerErrRemark: "注册失败详情",
    dataSource: "数据来源",
    calculateMode: "计算方式",
    settleFrequencyId: "结算分类",
    sewageAmount: "污水总金额",
    hydraulicPressure: "水压",
    eqptManagerUserId: "管理员",
    eqptManagerName: "管理员姓名",
    eqptManagerPhone: "管理员电话号码",
    eqptComPlatform: "通讯平台",
    reverseAccumulateHours: "反向累计工作时长",
    dailyReverseWaterConsumption: "日反向用水总量",
    reverseWorkingHours: "日反向用水总时长",
    slightLeakPressureValue: "轻微泄压阈值",
    leakPressureValue: "泄压阈值",
    seriousLeakPressureValue: "严重泄压阈值",
    // 工单
    woId: "工单id",
    woDescription: "工单描述",
    woCreatorName: "创建人",
    woCreateTime: "创建时间",
    woStatus: "工单状态",
    woDisposerName: "处置人",
    woDisposerPhone: "处置人电话",
    woDisposeTime: "处置时间",
    woDisposeSolution: "解决方案",
    woDisposeAttachment: "附件信息",
    woAuditorName: "审核人",
    woAuditTime: "审核时间",
    woAuditOpinion: "审核意见",
    woCompleteTime: "完成时间",
    woResource: "工单来源",
    priceName:'名称',
    priceType:'水价类型',
    costType:'费用类型',
    constantUnitPrice:'固定水价',
    chargeCycle:'计费周期',
    chargeName:'计费名称',
    costAmount: '金额',
    companyName: "公司",
    companyUnitName: "部门",
    areaName: "小区",
    buildingName: "楼栋",
    unitName: "单元",
    familyName: "户",
    accountUserName: "户主姓名",
    userPhone: "电话",
    // 商户
    merchantName:'商户名称',
    merchantType:'商户类型',
    outMchId:'商户号',
    transactionAmount:'交易金额',
    payMethod:'支付方式',
    payChannel:'支付渠道',
    transactionStatus:'交易状态',
    transactionId:'订单号',
    outTransactionId:'外部订单号',
    payTime:'支付时间',
    privateUserNo: '私有户号',
    
    // 角色
    roleName: "名称",
    roleCode: "编码",
    roleDesc: "描述",

    // 许可
    permissionName: "名称",
    permissionCode: "编码",
    permissionDesc: "描述",
    
  },
  table: {
    noDataText: "没有数据",
    noFilteredDataText: "没有过滤数据",
  },
  DataWithUnit: {
    alarmAngleValue: "报警角度(单位：°)",
    angleAlarmInterval: "角度报警间隔(单位：分钟)",
    alarmTemperatureValue: "报警温度(单位：℃)",
    temperatureAlarmInterval: "温度报警间隔(单位：分钟)",
    reportInterval: "固定上报间隔(单位：秒)",
    turbulenceAlarmTimes: "颠簸报警次数(单位：次)",
    valveStatus: "阀门状态",
    uploadServer: "服务器地址",
    uploadCycle: "上传周期",
    settlementDate: "结算日",
    synchronizationData: "机电同步数据",
    surplusAmountWarningValue: "剩余金额告警值",
    waterPrice1: "水价1：小于等于用量1时的价格(单位：元)",
    usage1: "用量1(单位:立方米)",
    waterPrice2: "水价2：介于用量1与用量2之间的价格(单位：元)",
    usage2: "用量2(单位:立方米)",
    waterPrice3: "水价3：高于用量2时的价格(单位：元)",
    chargeMode: "计费方式",
    slightLeakPressureValue: "轻微泄压阈值（KPa）",
    leakPressureValue: "泄压阈值（KPa）",
    seriousLeakPressureValue: "严重泄压阈值（KPa）",
    hydraulicPressureAlarmInterval: "液压报警间隔（分钟）",
  },
};
